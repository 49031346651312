@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #F0DAC5;
  }

.App {
    background-color: #F0DAC5;
    height: 100%;
    width: 100%;
  }

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

/* COLOR PALETTE 
LIGHT BROWN: #F0DAC5
NAVY BLUE: #1C2340
MAGENTA: #50223C
*/
.header-outside-container {
  box-sizing: border-box;
  margin: 0;
  width: 100vw;
  font-family: "Lato", serif;
  color: #1C2340;
  background-color: #F0DAC5;
  border-bottom: 5px solid #50223C;
}
.header-outside-container header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.header-outside-container header .title-name {
  width: 50%;
  text-align: left;
  margin-top: 1rem;
}
.header-outside-container header .title-name h1 {
  font-family: "Merriweather", serif;
  font-size: 36px;
  padding-left: 8%;
}
.header-outside-container .navigation-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}
.header-outside-container .navigation-menu-container .navigation-menu-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1C2340;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}
.menu-bars h2 {
  text-decoration: none;
  color: #F0DAC5;
}

.nav-menu {
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
  position: fixed;
  z-index: 1;
  background-color: #1C2340;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  color: #F0DAC5;
}

.nav-text a {
  text-decoration: none;
  color: #F0DAC5;
  font-size: 18px;
  height: 100%;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

@media only screen and (max-width: 768px) {
  .navigation-menu-options {
    position: fixed;
    left: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
  }

  header {
    justify-content: space-between;
  }
}

/*# sourceMappingURL=header.css.map */

/* COLOR PALETTE 
LIGHT BROWN: #F0DAC5
NAVY BLUE: #1C2340
MAGENTA: #50223C
*/
.footer {
  height: 15vh;
  padding-top: 45%;
  text-align: center;
  background-color: #F0DAC5;
  color: #555548;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.social-icons a {
  color: #555548;
}
.social-icons a:hover {
  color: #555548;
  font-style: underline;
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .footer h2 {
    font-size: 18px;
  }
}

/*# sourceMappingURL=footer.css.map */

/* COLOR PALETTE 
LIGHT BROWN: #F0DAC5
NAVY BLUE: #1C2340
MAGENTA: #50223C
*/
.about-container {
  font-family: "Lato";
  align-items: center;
  padding-top: 7%;
  width: 100%;
  background-color: #F0DAC5;
  padding-bottom: 3%;
  height: 90vh;
}

.about {
  text-align: center;
  padding-bottom: 3%;
  display: flex;
  flex-direction: row;
  vertical-align: center;
}
.about .blurb {
  line-height: 40px;
  vertical-align: center;
  font-size: 18px;
  width: 45%;
  padding-left: 3%;
  padding-top: 3%;
  position: relative;
}
.about .blurb a {
  color: #1C2340;
  text-decoration: none;
}
.about .blurb a:hover {
  color: #50223C;
  font-style: italic;
}
.about .image {
  margin-left: 4%;
  width: 45%;
  position: relative;
}
.about .image img {
  width: 70%;
}

.about-middle-divider {
  border: 3px solid #50223C;
}

.bottom-border {
  border: 3px solid #50223C;
}

#link_projects {
  color: #50223C;
}

@media only screen and (max-width: 768px) {
  .about {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about .blurb {
    line-height: 25px;
    width: 80%;
    padding-top: 5%;
  }
  .about .image {
    width: 80%;
  }
  .about .about-middle-divider {
    border: 3px solid #50223C;
    height: 5%;
    width: 80%;
    margin-top: 8%;
  }

  a {
    color: #1C2340;
    text-decoration: none;
  }

  a:hover {
    color: #50223C;
    font-style: italic;
  }
}

/*# sourceMappingURL=about.css.map */

/* COLOR PALETTE 
LIGHT BROWN: #F0DAC5
NAVY BLUE: #1C2340
MAGENTA: #50223C
*/
h2 {
  text-align: center;
  padding-top: 2%;
}

.content {
  color: #1C2340;
  height: 100%;
}

#card0 > img {
  width: 90%;
}

#card1 > img {
  width: 80%;
}

#card2 > img {
  width: 100%;
}

#card3 > img {
  width: 100%;
}

#card4 > img {
  width: 100%;
}

.projects-main {
  height: 200%;
  background-color: #F0DAC5;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50%;
}
.projects-main .projects-container {
  padding-top: 6%;
  background-color: #F0DAC5;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: 48% 48%;
  grid-template-rows: 40% 40% 40%;
  grid-row-gap: 1%;
  row-gap: 1%;
  grid-column-gap: 2%;
  -webkit-column-gap: 2%;
          column-gap: 2%;
}
.projects-main .projects-container .project-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #F0DAC5;
  padding-bottom: 15%;
}
.projects-main .projects-container #container0 {
  grid-column: 1/2;
  grid-row: 1/2;
}
.projects-main .projects-container #container1 {
  grid-column: 2/3;
  grid-row: 1/2;
}
.projects-main .projects-container #container2 {
  grid-column: 1/2;
  grid-row: 2/3;
}
.projects-main .projects-container #container3 {
  grid-column: 2/3;
  grid-row: 2/3;
}
.projects-main .projects-container #container4 {
  grid-column: 1/2;
  grid-row: 3/4;
}

.project-info a {
  color: #1C2340;
  font-style: bold;
}

@media only screen and (max-width: 576px) {
  .projects-main {
    padding-top: 5%;
  }
  .projects-main .projects-container {
    display: flex;
    flex-direction: column;
  }

  .project-card {
    width: 100%;
  }
  .project-card img {
    width: 100%;
  }

  .project-info {
    padding-top: 3%;
    width: 100%;
  }
  .project-info div {
    width: 100%;
    text-align: center;
  }

  #card1 {
    order: 1;
  }
}

/*# sourceMappingURL=projects.css.map */

.media-container {
  height: 80vh;
  padding-top: 25%;
}

/*# sourceMappingURL=media.css.map */

/* COLOR PALETTE 
LIGHT BROWN: #F0DAC5
NAVY BLUE: #1C2340
MAGENTA: #50223C
*/
h1, h2, h3, h4 {
  font-family: "Lato";
}

li {
  list-style: none;
}

.main {
  background-color: #F0DAC5;
  color: #1C2340;
  width: 100vw;
  height: 100%;
}
.main .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .main {
    padding-bottom: 15%;
  }
}

/*# sourceMappingURL=main.css.map */

